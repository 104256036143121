<template>
  <div class="container" id="container">
    <div v-if="ashow == true" class="shlog">
      <div class="shin">
        <img class="shlog1" src="@/assets/images/share_action_icon@2x.png" />
        <img class="shlog2" src="@/assets/images/pic_action_icon@2x.png" />
        <div class="shlog4">点击右上角“...”，分享到</div>
        <div class="shlog3">
          <img class="sslog" src="@/assets/images/wx_action_icon@2x.png" />
          <span>微信好友或</span>
          <img class="sslog1" src="@/assets/images/pyq_action_icon@2x.png" />
          <span>微信朋友圈~</span>
          <div class="known" @click="ashow = false">知道了</div>
        </div>
      </div>
    </div>
    <div class="title">{{ list.Title }}</div>
    <div class="item_label">
      <span v-for="(item, index) in slName" :key="index">{{ item }}</span>
    </div>
    <div class="timetab">
      <div class="time">
        {{ list.CreateTime | dateFilter(list.CreateTime) }}
      </div>
      <!-- <div class="fontSize">
        字号
        <img src="@/assets/images/switchSmall@2x.png" />
      </div> -->
    </div>
    <div>
      <div class="sicon">
        <img
          style="vertical-align: middle; margin-right: 5px"
          src="@/assets/images/browse_comm_icon@2x.png"
        />
        <span style="vertical-align: middle">{{ list.WatchCount }}</span>
      </div>
      <div class="sicon">
        <img
          style="width: 1.3em; vertical-align: middle; margin-right: 5px"
          src="@/assets/images/like_comm_icon@2x.png"
        />
        <span style="vertical-align: middle">{{ list.LikeCount }}</span>
      </div>
    </div>
    <div class="content1" v-show="list.Type == 1">
      <!-- <iframe
        class="frame"
        :src="list.Video"
        scrolling="auto"
        frameborder="0"
      ></iframe> -->
      <!-- <video
        id="player-container-id"
        width="430"
        height="270"
        preload="auto"
        playsinline
        webkit-playsinline
      ></video> -->
      <div style="width: 100%; height: 320px;">
        <video
          id="player-container-id"
          style="width: 100%; height: 100%;"
          preload="auto"
          playsinline=""
          webkit-playsinline=""
          x5-playsinline=""
        ></video>
      </div>
      <div class="detail">
        <!-- {{ list.VContent }} -->
        <div
          class="VContent"
          v-html="list.VContent"
          @click="imagePreview($event)"
        ></div>
        <div
          class="banner"
          v-html="list.ImageText"
          @click="imagePreview($event)"
        >
        </div>
      </div>
    </div>

    <div class="content2" v-if="list.Type == 2">
      <div class="detail">
        <!-- {{ list.VContent }} -->
        <div
          class="banner"
          v-html="list.ImageText"
          @click="imagePreview($event)"
        >
        </div>
      </div>
    </div>

    <div class="content3" v-if="list.Type == 3">
      <div class="detailzb">
        <div class="subscribeBox">
          <span class="markIcon" v-if="list.LiveState == '1' || list.LiveState == '2'" @click="explain">预约说明<img src="@/assets/images/mark.png" /></span>
          <van-button
            type="primary"
            class="want"
            @click="addyy"
            v-if="yyyes == false && list.LiveState == '1'"
            >直播预约</van-button
          >
          <van-button
            type="primary"
            class="want"
            @click="delyy"
            v-if="yyyes == true && list.LiveState == '1'"
            v-show="yyshow"
            >取消直播预约</van-button
          >
          <van-button
            type="primary"
            class="want"
            @click="enteryy"
            v-if="list.LiveState == '2'"
            >进入直播</van-button
          >
          <van-button
            type="primary"
            class="want ReviewWant"
            :class="{'disable': list.IsReturn != 1}"
            @click="addReview"
            v-if="Reviewyes == false && (list.LiveState == '1' || list.LiveState == '2')"
            >回看预约</van-button
          >
          <van-button
            type="primary"
            class="want ReviewWant"
            @click="delReview"
            v-if="Reviewyes == true && (list.LiveState == '1' || list.LiveState == '2')"
            v-show="Reviewshow"
            >取消回看预约</van-button
          >

          <!-- 直播结束 -->
          <van-button
            type="primary"
            class="want"
            @click="addReview"
            v-if="Reviewyes == false && list.LiveState == '3' && list.IsReturn == 1 && RetArticleList.length == 0"
            >回看预约</van-button
          >
          <van-button
            type="primary"
            class="want"
            @click="delReview"
            v-if="Reviewyes == true && list.LiveState == '3' && list.IsReturn == 1 && RetArticleList.length == 0"
            v-show="Reviewshow"
            >取消回看预约</van-button
          >
          <van-button
            type="primary"
            class="want"
            @click="toReview"
            v-if="list.LiveState == '3' && list.IsReturn == 1 && RetArticleList.length > 0"
            >观看回看</van-button
          >
          <van-popup v-model="showRetArticleList">
            <div class="RetArticleListBox">
              <van-icon class="icon_close" name="cross" @click="showRetArticleList = false" />
              <span class="retTitle">回看列表</span>
              <div class="listBox">
                <div class="retList" v-for="(item, index) in RetArticleList" :key="index" @click="toRetArticle(item.Id, item.Type)">
                  {{item.Title}}
                </div>
              </div>
            </div>
          </van-popup>
        </div>
        <div
          class="banner"
          v-html="list.ImageText"
          @click="imagePreview($event)"
        ></div>
        <!-- <img
          v-for="(item, index) in list.fileList"
          :key="index"
          class="Thumbnail"
          style="width: 100%; margin: 0"
          :src="item.Url"
        /> -->
      </div>
    </div>

    <div class="article" v-if="list.Type !== 3 || module != 1">
      <div class="articletitle">参考文献</div>
      <div
        class="articleinfo"
        style="white-space: pre-wrap"
        v-html="list.Reference"
      >
      </div>
    </div>

    <div
      class="filedownload"
      v-if="list.Type == 2 && list.FilesName != '' && list.FilesName != null"
    >
      <img src="@/assets/images/pdf_ny_tab@2x.png" />
      <div class="fileTitle">
        <a :href="list.FilesUrl" :download="list.FilesName"></a>
        {{ list.FilesName }}
      </div>
      <div class="filebottom" @click="filedownload(list.FilesUrl)">
        点击下载>>
      </div>
    </div>

    <div class="dzmain" v-if="list.Type !== 3 || module != 1">
      <div class="dztitle">欢迎您对本文打星评价</div>
      <div class="raterow">
        <div class="ratename">专业度</div>
        <van-rate
          v-model="rate1"
          :size="25"
          color="#ffd21e"
          void-icon="star"
          void-color="#eee"
          gutter="13px"
        />
      </div>
      <div class="raterow">
        <div class="ratename">实用性</div>
        <van-rate
          v-model="rate2"
          :size="25"
          color="#ffd21e"
          void-icon="star"
          void-color="#eee"
          gutter="13px"
        />
      </div>
      <div class="raterow">
        <div class="ratename">推荐度</div>
        <van-rate
          v-model="rate3"
          :size="25"
          color="#ffd21e"
          void-icon="star"
          void-color="#eee"
          gutter="13px"
        />
      </div>

      <van-button
        type="primary"
        class="rateupload"
        @click="addArticleStars()"
        v-if="ratebtnshow == 0"
        >提交</van-button
      >
      <van-button
        type="primary"
        class="rateupload"
        @click="editArticleStars()"
        v-else
        >修改评分</van-button
      >
    </div> 

    <div class="competitionBox" v-if="module == 1">
      <p class="tips1">请为您喜欢的作品点赞！</p>
      <p class="tips2">参赛报名：请下载领医迈APP，进入极限挑战专区，作品上传通道</p>
      <img class="lymQRcode" src="@/assets/images/lym.png" />
    </div>

    <div class="rowbtn">
      <span class="headline">评论</span>
      <div class="plbtn" @click="showPopup">
        <img src="@/assets/images/appraise.png" />
        <span>我要点评</span>
      </div>
    </div>

    <van-popup
      v-model="show"
      closeable
      position="bottom"
      :style="{ height: '28%' }"
      class="plPopup"
    >
      <van-cell-group class="plinput">
        <van-field v-model="plform.Content" placeholder="请输入评论内容" />
      </van-cell-group>
      <van-button type="primary" class="rateupload" @click="plupload"
        >提交</van-button
      >
    </van-popup>

    <div class="plmain">
      <div
        class="plrow noComment"
        v-if="Object.keys(CommentList).length == 0"
      >
        <img src="@/assets/images/none_comm@2x.png" />
        <span>暂无评论</span>
      </div>
      <div
        class="plrow"
        v-else
        v-for="(item, index) in CommentList"
        :key="index"
      >
        <div class="pltop">
          <div class="tx">
            <img class="plicon" style="" :src="item.Headimgurl" />
          </div>
          <div class="plname">{{ item.CusRealName }}</div>
          <div class="pltime">{{ item.Time | dateFilter(item.Time) }}</div>
        </div>
        <div class="pltext">
          {{ item.Content }}
        </div>

        <div class="pldz">
          <img
            class="plicon"
            style=""
            v-if="item.LikeState == false"
            src="@/assets/images/like.png"
            @click="pldz(item.Id)"
          />
          <img
            class="plicon"
            style=""
            v-else
            src="@/assets/images/like_active.png"
            @click="plqxdz(item.Id)"
          />
          <span>{{ item.LikeCount }}</span>
        </div>
      </div>
    </div>

    <div class="aboutmain">
      <div class="abouttop">相关内容</div>
      <div class="aboutrow" v-if="showRecommendList" style="text-align: center">
        敬请期待
      </div>
      <div
        class="aboutrow"
        v-for="(item, index) in list.RecommendList"
        :key="index"
        @click="jump(item.Id, item.Type)"
      >
        <div class="aboutimg">
          <img class="imgmain" :src="item.Thumbnail" />
          <img class="playicon" src="@/assets/images/play_comm_icon@2x.png" />
        </div>
        <div class="aboutmaininfo">
          <div class="abouttitle">
            {{ item.Title }}
          </div>
          <div class="aboutname">讲者：{{ item.Speaker }}</div>
          <div class="abouthos">医院：{{ item.SHospital }}</div>
          <div>
            <div class="sicon ssicon" style="margin-left: 0">
              <img
                style="width: 1.3em"
                src="@/assets/images/browse_comm_icon@2x.png"
              />
              {{ item.WatchCount }}
            </div>
            <div class="sicon ssicon">
              <img
                style="width: 1.2em"
                src="@/assets/images/like_comm_icon@2x.png"
              />
              {{ item.LikeCount }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="toTop" @click="toTop"  v-if="code != 'csekt'">
      <a href="javascript:void(0)">
        <img src="@/assets/images/top_comm_icon@2x.png" />
      </a>
    </div>

    <div class="xcpsy" @click="toNewProduct">
      <img src="@/assets/images/productImg.png" />
      <span>产品信息咨询</span>
    </div>
 
    <div class="wytbl" v-if="code == 'csekt'" @click="toEnroll">
      <img src="@/assets/images/wytbl.png" />
      <span>我要参与</span>
    </div>

    <div class="pluswj" @click="toQuestionnaire">
      <div class="iconBox">
        <img src="@/assets/images/wenjuan.png" />
      </div>
      <span>问卷调研</span>
    </div>

    <div class="bottombar">
      <div class="bottomrow" @click="goback">
        <div>
          <img
            class="bottomimg"
            src="@/assets/images/back.png"
          />
        </div>
        <div class="bottomtext">返回</div>
      </div>
      <div class="bottomrow" @click="adddz" v-if="dzyes == false">
        <div>
          <img
            class="bottomimg"
            src="@/assets/images/good_ny_icon_nor@2x.png"
          />
        </div>
        <div class="bottomtext">点赞</div>
      </div>
      <div class="bottomrow" @click="deldz" v-else v-show="dzshow">
        <div>
          <img
            class="bottomimg"
            src="@/assets/images/good_ny_icon_alt@2x.png"
          />
        </div>
        <div class="bottomtext">点赞</div>
      </div>
      <template v-if="module != 1">
        <div class="bottomrow" @click="addsc" v-if="scyes == false">
          <div>
            <img
              class="bottomimg"
              src="@/assets/images/collect_ny_icon_nor@2x.png"
            />
          </div>
          <div class="bottomtext">收藏</div>
        </div>
        <div class="bottomrow" @click="delsc" v-else v-show="scshow">
          <div>
            <img
              class="bottomimg"
              src="@/assets/images/collect_ny_icon_alt@2x.png"
            />
          </div>
          <div class="bottomtext">收藏</div>
        </div>
      </template>
      <div class="bottomrow" @click="sharefunc">
        <div>
          <img
            class="bottomimg"
            src="@/assets/images/share_ny_icon_nor@2x.png"
          />
        </div>
        <div class="bottomtext">转发</div>
      </div>
    </div>

    <!-- 关注提示弹框 -->
    <div class="QRCodeDialog" v-if="IsShowQRCode">
      <p>请扫描下方二维码<br>关注公众号</p>
      <img class="QRCodeImg" src="@/assets/images/QRCode.jpg"/>
      <span class="closeQRCodeDialog" @click="IsShowQRCode = false">关闭</span>
    </div>

    <!-- 视频播放弹框 -->
    <div class="videoPopupBox">
      <van-popup v-model="videoPopupShow" :overlay-style="{background: 'black'}" :get-container="getContainer" @closed="videoPopupClosed">
        <div id="videoPopupDiv" style="width: 100%; height: 320px; background-color: black;">
          <video
            id="player-popup-id"
            style="width: 100%; height: 100%;"
            preload="auto"
            playsinline=""
            webkit-playsinline=""
            x5-playsinline=""
          ></video>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapState } from "vuex";
import { Dialog, ImagePreview } from "vant";
import wx from "weixin-js-sdk";
import utils from "../../common/utils/utils";
export default {
  name: "ContentPage",
  data() {
    return {
      appId: "",
      timestamp: "",
      nonceStr: "",
      signature: "",
      list: [],
      Comment: {},
      CommentList: [],
      slName: [],
      watched: {},
      dzt: {},
      ztmain: [],
      id: 0,
      checked: false,
      rate1: 0,
      rate2: 0,
      rate3: 0,
      current: 0,
      show: false,
      customerid: "",
      plform: {
        ArticleId: 0,
        CustomerId: "",
        Type: 4,
        Content: "",
      },
      CommentLikelist: {
        CustomerId: "",
        CommentId: "",
      },
      dzyes: false,
      scyes: false,
      yyyes: false,
      Reviewyes: false,
      dzshow: false,
      scshow: false,
      yyshow: false,
      Reviewshow: false,
      getstar: {},
      stars: [],
      ratebtnshow: 0,
      shareurl: {},
      weixinvalue: {
        appId: "",
        timestamp: "",
        noncStr: "",
        signature: "",
      },
      utype: "",
      backlog: {
        ShareUid: 0,
        SeeUid: 0,
        ShareUri: "",
        Code: "",
        ShareId: 0,
        seeType: "",
      },
      sharefrom: 0,
      sharecode: "",
      ssurl: "",
      timepoint: {
        ArticleId: "",
        Type: "",
        Date: "",
      },
      VideoTimer: null,
      timer: null,
      pathname: null,
      //fxUrl: "",
      uprate1: 0,
      uprate2: 0,
      uprate3: 0,
      showRecommendList: false,
      player: null,
      initPlayer: true,
      ashow: false,
      kpng: require("../../assets/images/k.png"),
      IsGetFansUser: false,
      IsFansUser: null,
      IsShowQRCode: false,
      RetArticleList: [],
      showRetArticleList: false,
      videoPopupShow: false,
      videoInfo: null
    };
  },
  computed: {
    ...mapState(["userInfo", "Openid"]),
    code() {
      return this.$route.query.code;
    },
    module() {
      // 1:极线挑战专区
      return this.$route.query.module;
    }
  },
  setup() {
    const checked = ref(true);
    return { checked };
  },
  rate1() {
    const rate1 = ref(0);
    return { rate1 };
  },
  rate2() {
    const rate2 = ref(0);
    return { rate2 };
  },
  rate3() {
    const rate3 = ref(0);
    return { rate3 };
  },
  created() {
    this.getIsFansUser();
    this.getInfo();
    this.getRetArticle();
    // this.getUrl();
    this.getComment();
    this.enter();
    this.dzzt();
    this.getArticleStars();
    this.initShare();
    this.fromLog();

    setTimeout(() => {
      if(!this.IsFansUser) {
        this.IsShowQRCode = true;
      }
    }, 40000)
  },
  mounted() {
    window.addEventListener('scroll', () => {
      // 获取距离顶部的距离
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // 获取可视区的高度
      let clientHeight = document.documentElement.clientHeight;
      // 获取滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        // console.log("滚动到底部了")
        if(this.IsGetFansUser) {
          if (!this.IsFansUser) {
            this.IsShowQRCode = true;
          }
        }
      }
    }, true)
    // this.initVideo();
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }

    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }

    if (this.VideoTimer) {
      clearInterval(this.VideoTimer);
      this.VideoTimer = null;
    }
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }

    if (this.VideoTimer) {
      clearInterval(this.VideoTimer);
      this.VideoTimer = null;
    }
  },
  methods: {
    getContainer() {
      return document.querySelector('.videoPopupBox');
    },
    // 获取回看
    getRetArticle() {
      this.$axios.get("/Api/Api/Web/Article/GetRetArticle?id=" + this.id).then(res => {
        this.RetArticleList = res.Data;
      });
    },
    // 获取用户是否已关注公众号
    getIsFansUser() {
      this.$axios.get("/Api/Api/Web/GetWxUser/GetIsFansUser?openid=" + this.Openid).then(res => {
        this.IsGetFansUser = true;
        if (res.Data) {
          this.IsFansUser = true;
        } else {
          this.IsFansUser = false;
        }
      });
    },
    //记录分享人行为数据
    fromLog() {
      //alert("记录分享人行为数据-开始");
      //从url参数中获取分享人id和code
      this.utype = this.$route.query.seetype;
      //alert("记录分享人行为数据-utype："+this.utype);
      if (this.utype == 2) {
        //alert("记录分享人行为数据-进入-utype=2");
        //sharefrom  sharecode
        this.sharefrom = this.$route.query.shareuid;
        this.sharecode = this.$route.query.code;
        this.sharelog();
      }
    },
    // 初始化直播
    initVideo() {
      let Options = {};
      if(this.list.fileid) {
        Options = {
          fileID: this.list.fileid,
          appID: this.list.appid,
          psign: this.list.psign,
          licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1307224350_1/v_cube.license'
        };
        this.player = this.TCPlayer("player-container-id", Options, {
          app_Play_Id: this.list.app_Play_Id,
          app_Id: 'wcwechat',
          user_Id: this.list.user_Id,
          video_Id: this.list.Id
        },{
          Id: this.userInfo.Id,
          RealName: this.userInfo.RealName,
          HospitalTitle: this.userInfo.HospitalTitle,
          PositionalName: this.userInfo.PositionalName,
          SubjectName: this.userInfo.SubjectName,
          Phone: this.userInfo.Phone
        }, this.list.Title, this.list.videoSize, this.list.fileid).player;
      } else {
        Options = {
          playbackRates: [0.5, 1, 1.5, 2],
          licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1307224350_1/v_cube.license'
        };
        this.player = this.TCPlayer("player-container-id", Options).player;
        this.player.src(this.list.Video);
      }
      this.player.on("play", () => {
        // console.log('播放', this.VideoTimer)
        let d = new Date().getTime();
        this.$axios.post("/Api/Api/Web/BuriedPoint/AddDur", {
          ArticleId: this.list.Id,
          Type: 2,
          Date: d,
        }).then((res) => {
          if (!this.VideoTimer) {
            this.VideoTimer = setInterval(() => {
              this.UpdateDur(res.Data);
            }, 10000);
          }
        });
      });
      this.player.on("pause", () => {
        // console.log('暂停', this.VideoTimer)
        if (this.VideoTimer) {
          console.log('清空视频定时')
          clearInterval(this.VideoTimer);
          this.VideoTimer = null;
        }
      })
    },
    UpdateDur(Id) {
      let time = new Date().getTime();
      this.$axios.post("/Api/Api/Web/BuriedPoint/UpdateDur", {
        Id: Id,
        Date: time,
      });
    },
    getInfo() {
      this.id =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      let that = this;
      if (this.id > 0) {
        that.$axios
          .post("/Api/Api/Web/Article/GetArticleDetile?id=" + this.id)
          .then((res) => {
            let data = res.Data;
            if (res.Data.RecommendList.length == 0) {
              this.showRecommendList = true;
            }
            that.list = data;

            if (data.Type == "1") {
              this.initVideo();
            }

            // if (data.Type == "2" || data.Type == "3") {
              this.timeline();
            // }

            if (res.Data.SLName) {
              this.slName = res.Data.SLName.split(",");
              this.slName.splice(this.slName.indexOf(""), 1);
            }
            that.loading = false;
          });
      }
    },

    initShare() {
      let that = this;
      const entryLink = utils.getEntryLink();
      that.$axios
        .get("/Api/Api/web/WechatHelper/GetWechat?backUrl=" + entryLink)
        .then((res) => {
          wx.config({
            debug: false,
            appId: res.Data.AppId,
            timestamp: res.Data.TimesTamp,
            nonceStr: res.Data.NonceStr,
            signature: res.Data.She1Str,
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
          });
          wx.checkJsApi({
            jsApiList: [
              // 所有要调用的 API 都要加到这个列表中
              "updateAppMessageShareData", // 分享到朋友圈接口
              "updateTimelineShareData", //  分享到朋友接口
            ],
            success: async function (res) {
              var url = await that.getShareUrl();
              wx.ready(() => {
                wx.updateAppMessageShareData({
                  title: that.list.Title,
                  link: url,
                  desc: that.list.Details ? that.list.Details : '',
                  imgUrl: "https://wcwechat.jjmc.cn/" + that.kpng,
                  success: function (res) {},
                });
                wx.updateTimelineShareData({
                  title: that.list.Title,
                  link: url,
                  desc: that.list.Details ? that.list.Details : '',
                  imgUrl: "https://wcwechat.jjmc.cn/" + that.kpng,
                  success: function (res) {},
                });
              });
            },
          });
        });
    },

    sharefunc() {
      let that = this;
      //
      //这里提示用户去右上角分享
      //
      that.ashow = true;
      //记录分享日志
      that.sharelog();
    },

    getShareUrl() {
      let that = this;
      this.shareurl.customerid = this.userInfo.Id;      
      var shurl = window.location.href;
      var shindex = shurl.indexOf("&seetype");
      if (shindex > -1) {
        shurl = shurl.substr(0, shindex);
      }
      //console.log("85825225",window.location.href,shurl)
      this.shareurl.url = encodeURIComponent(shurl);
      return new Promise((resolve) => {
        that.$axios
          .get(
            "/Api/Api/web/ShareLog/GetShareUrl?url=" +
              that.shareurl.url +
              "&customerid=" +
              this.shareurl.customerid
          )
          .then((res) => {
            resolve(res.Data.Url);
          });
      });
    },

    sharelog() {
      //alert("分享记录-开始");
      let that = this;
      that.backlog = {
        ShareUid: this.sharefrom,
        SeeUid: this.userInfo.Id,
        ShareUri: window.location.href,
        Code: this.sharecode,
        ShareId: this.id,
        seeType: this.utype,
        Type: 1
      };
      //alert("分享记录-拿到信息"+JSON.stringify(that.backlog));

      that.$axios
        .post("/Api/Api/Web/ShareLog/AddShare", that.backlog)
        .then((res) => {});
    },

    getComment() {
      this.Comment.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.Comment.CustomerId = this.userInfo.Id;
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/GetComment", this.Comment)
        .then((res) => {
          that.CommentList = res.Data;
          that.loading = false;
        });
    },
    onChange(index) {
      this.current = index;
    },
    showPopup() {
      this.show = true;
    },
    plupload() {
      this.plform.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.plform.CustomerId = this.userInfo.Id;
      let that = this;
      if(this.plform.Content) {
      that.$axios
        .post("/Api/Api/Web/Article/AddComment", this.plform)
        .then((res) => {
          if (res.RetCode == "10000") {
            Dialog.alert({
              message: "提交成功，审核通过后可以看到该评论",
            }).then(() => {
              this.show = false;
            });
          }
        });
      } else {
        this.$toast.fail('内容不能为空');
      }
    },
    pldz(id) {
      this.CommentLikelist.CustomerId = this.userInfo.Id;
      this.CommentLikelist.CommentId = id;
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/AddCommentLike", this.CommentLikelist)
        .then((res) => {
          // this.list.CommentList.LikeState = true;
          this.getComment();
        });
    },
    plqxdz(id) {
      this.CommentLikelist.CustomerId = this.userInfo.Id;
      this.CommentLikelist.CommentId = id;
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/delCommentLike", this.CommentLikelist)
        .then((res) => {
          // this.list.CommentList.LikeState = true;
          this.getComment();
        });
    },
    dzzt() {
      this.dzt.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.dzt.CustomerId = this.userInfo.Id;
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/HandleState", this.dzt)
        .then((res) => {
          this.ztmain = res.Data;
          for (var i = 0; i < this.ztmain.length; i++) {
            if (this.ztmain[i].type == "3" && this.ztmain[i].State == true) {
              this.dzyes = true;
              this.dzshow = true;
            }
            if (this.ztmain[i].type == "2" && this.ztmain[i].State == true) {
              this.scyes = true;
              this.scshow = true;
            }
            if (this.ztmain[i].type == "5" && this.ztmain[i].State == true) {
              this.yyyes = true;
              this.yyshow = true;
            }
            if (this.ztmain[i].type == "7" && this.ztmain[i].State == true) {
              this.Reviewyes = true;
              this.Reviewshow = true;
            }
          }
        });
    },

    timeline() {
      let that = this;
      let d = new Date().getTime();
      // if (this.list.type == "1") {
      //   this.timepoint.Type = "2";
      // } else {
      //   this.timepoint.Type = "1";
      // }
      this.timepoint.Type = "1";
      this.timepoint.ArticleId = this.list.Id;
      this.timepoint.Date = d;
      that.$axios
        .post("/Api/Api/Web/BuriedPoint/AddDur", this.timepoint)
        .then((res) => {
          if (!this.timer) {
            this.timer = setInterval(() => {
              let time = new Date().getTime();
              that.$axios.post("/Api/Api/Web/BuriedPoint/UpdateDur", {
                Id: res.Data,
                Date: time,
              });
            }, 10000);
          }
        });
    },

    enter() {
      this.watched.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.watched.CustomerId = this.userInfo.Id;
      this.watched.type = "1";
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/Handle", this.watched)
        .then((res) => {
          // this.list.CommentList.LikeState = true;
        });
    },
    adddz() {
      this.watched.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.watched.CustomerId = this.userInfo.Id;
      this.watched.type = "3";
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/Handle", this.watched)
        .then((res) => {
          this.dzzt();
          this.dzshow = true;
          // this.list.CommentList.LikeState = true;
        });
    },
    deldz() {
      this.watched.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.watched.CustomerId = this.userInfo.Id;
      this.watched.type = "3";
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/DelHandle", this.watched)
        .then((res) => {
          this.dzyes = false;
          // this.dzzt();
          // this.list.CommentList.LikeState = true;
        });
    },
    addsc() {
      this.watched.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.watched.CustomerId = this.userInfo.Id;
      this.watched.type = "2";
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/Handle", this.watched)
        .then((res) => {
          this.scshow = true;
          this.dzzt();
          // this.list.CommentList.LikeState = true;
        });
    },
    delsc() {
      this.watched.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.watched.CustomerId = this.userInfo.Id;
      this.watched.type = "2";
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/DelHandle", this.watched)
        .then((res) => {
          this.scshow = false;
          this.scyes = false;
          // this.dzzt();
          // this.list.CommentList.LikeState = true;
        });
    },
    // 直播预约
    addyy() {
      this.watched.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.watched.CustomerId = this.userInfo.Id;
      this.watched.type = "5";
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/Handle", this.watched)
        .then((res) => {
          this.yyshow = true;
          this.dzzt();
          // this.list.CommentList.LikeState = true;
        });
    },
    // 取消直播预约
    delyy() {
      this.watched.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.watched.CustomerId = this.userInfo.Id;
      this.watched.type = "5";
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/DelHandle", this.watched)
        .then((res) => {
          this.yyshow = false;
          this.yyyes = false;
          // this.dzzt();
          // this.list.CommentList.LikeState = true;
        });
    },
    // 回看预约
    addReview() {
      if(this.list.IsReturn == 1) {
        this.watched.ArticleId =
          typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
        this.watched.CustomerId = this.userInfo.Id;
        this.watched.type = "7";
        let that = this;
        that.$axios
          .post("/Api/Api/Web/Article/Handle", this.watched)
          .then((res) => {
            this.Reviewshow = true;
            this.dzzt();
            // this.list.CommentList.LikeState = true;
          });
      }
    },
    // 取消回看预约
    delReview() {
      this.watched.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.watched.CustomerId = this.userInfo.Id;
      this.watched.type = "7";
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/DelHandle", this.watched)
        .then((res) => {
          this.Reviewshow = false;
          this.Reviewyes = false;
          // this.dzzt();
          // this.list.CommentList.LikeState = true;
        });
    },
    enteryy() {
      let that = this;
      that.$axios
        .get("/Api/Api/Web/Live/LiveDocking?artId=" + this.list.Id)
        .then((res) => {
          window.location.href = res.Data;
        });
    },

    addArticleStars() {
      let that = this;
      this.getstar.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.getstar.CustomerId = this.userInfo.Id;
      if (that.rate1 == 1) {
        that.uprate1 = 20;
      } else if (that.rate1 == 2) {
        that.uprate1 = 40;
      } else if (that.rate1 == 3) {
        that.uprate1 = 60;
      } else if (that.rate1 == 4) {
        that.uprate1 = 80;
      } else if (that.rate1 == 5) {
        that.uprate1 = 100;
      }
      that.getstar.ProfessionalDegree = that.uprate1;
      if (that.rate2 == 1) {
        that.uprate2 = 20;
      } else if (that.rate2 == 2) {
        that.uprate2 = 40;
      } else if (that.rate2 == 3) {
        that.uprate2 = 60;
      } else if (that.rate2 == 4) {
        that.uprate2 = 80;
      } else if (that.rate2 == 5) {
        that.uprate2 = 100;
      }
      that.getstar.Practicability = that.uprate2;
      if (that.rate3 == 1) {
        that.uprate3 = 20;
      } else if (that.rate3 == 2) {
        that.uprate3 = 40;
      } else if (that.rate3 == 3) {
        that.uprate3 = 60;
      } else if (that.rate3 == 4) {
        that.uprate3 = 80;
      } else if (that.rate3 == 5) {
        that.uprate3 = 100;
      }
      that.getstar.RecommendationDegree = that.uprate3;
      that.$axios
        .post("/Api/Api/Web/Article/AddArticleStars", that.getstar)
        .then((res) => {
          Dialog.alert({
            message: "提交成功！",
          }).then(() => {
            this.getArticleStars();
          });
        });
    },

    editArticleStars() {
      let that = this;
      this.getstar.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.getstar.CustomerId = this.userInfo.Id;
      if (that.rate1 == 1) {
        that.uprate1 = 20;
      } else if (that.rate1 == 2) {
        that.uprate1 = 40;
      } else if (that.rate1 == 3) {
        that.uprate1 = 60;
      } else if (that.rate1 == 4) {
        that.uprate1 = 80;
      } else if (that.rate1 == 5) {
        that.uprate1 = 100;
      }
      that.getstar.ProfessionalDegree = that.uprate1;
      if (that.rate2 == 1) {
        that.uprate2 = 20;
      } else if (that.rate2 == 2) {
        that.uprate2 = 40;
      } else if (that.rate2 == 3) {
        that.uprate2 = 60;
      } else if (that.rate2 == 4) {
        that.uprate2 = 80;
      } else if (that.rate2 == 5) {
        that.uprate2 = 100;
      }
      that.getstar.Practicability = that.uprate2;
      if (that.rate3 == 1) {
        that.uprate3 = 20;
      } else if (that.rate3 == 2) {
        that.uprate3 = 40;
      } else if (that.rate3 == 3) {
        that.uprate3 = 60;
      } else if (that.rate3 == 4) {
        that.uprate3 = 80;
      } else if (that.rate3 == 5) {
        that.uprate3 = 100;
      }
      that.getstar.RecommendationDegree = that.uprate3;
      that.getstar.id = that.stars.Id;
      that.$axios
        .post("/Api/Api/Web/Article/UpdateArticleStars", that.getstar)
        .then((res) => {
          Dialog.alert({
            message: "修改成功！",
          }).then(() => {
            this.getArticleStars();
          });
        });
    },

    jump(Id, Type) {
      this.$router.push({
        path: "contentPage",
        query: {
          id: Id,
          type: Type,
        },
      });
      location.reload();
    },

    getArticleStars() {
      let that = this;
      that.rate1 = 0;
      that.rate2 = 0;
      that.rate3 = 0;
      that.$axios
        .post("/Api/Api/Web/Article/GetArticleStars", this.dzt)
        .then((res) => {
          that.rate1 = 0;
          that.rate2 = 0;
          that.rate3 = 0;
          that.stars = res.Data;
          if (that.stars.ProfessionalDegree == 20) {
            that.rate1 = 1;
          } else if (that.stars.ProfessionalDegree == 40) {
            that.rate1 = 2;
          } else if (that.stars.ProfessionalDegree == 60) {
            that.rate1 = 3;
          } else if (that.stars.ProfessionalDegree == 80) {
            that.rate1 = 4;
          } else if (that.stars.ProfessionalDegree == 100) {
            that.rate1 = 5;
          } else if (
            that.stars.ProfessionalDegree == 0 ||
            that.stars.ProfessionalDegree == null ||
            that.stars.ProfessionalDegree == undefined
          ) {
            that.rate1 = 0;
          }
          if (that.stars.Practicability == 20) {
            that.rate2 = 1;
          } else if (that.stars.Practicability == 40) {
            that.rate2 = 2;
          } else if (that.stars.Practicability == 60) {
            that.rate2 = 3;
          } else if (that.stars.Practicability == 80) {
            that.rate2 = 4;
          } else if (that.stars.Practicability == 100) {
            that.rate2 = 5;
          } else if (
            that.stars.Practicability == 0 ||
            that.stars.Practicability == null ||
            that.stars.Practicability == undefined
          ) {
            that.rate2 = 0;
          }
          if (that.stars.RecommendationDegree == 20) {
            that.rate3 = 1;
          } else if (that.stars.RecommendationDegree == 40) {
            that.rate3 = 2;
          } else if (that.stars.RecommendationDegree == 60) {
            that.rate3 = 3;
          } else if (that.stars.RecommendationDegree == 80) {
            that.rate3 = 4;
          } else if (that.stars.RecommendationDegree == 100) {
            that.rate3 = 5;
          } else if (
            that.stars.RecommendationDegree == 0 ||
            that.stars.RecommendationDegree == null ||
            that.stars.RecommendationDegree == undefined
          ) {
            that.rate3 = 0;
          }
          that.ratebtnshow = 1;
        });
    },
    filedownload(FilesUrl) {
      location.href = FilesUrl;
    },
    toTop() {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    toEnroll() {
      this.$router.push({
        path: 'enroll',
        query: {
          code: 'csekt',
          dcode: 'wytbl',
          tcode: 'csekt|wytbl|'
        }
      });
    },
    toNewProduct() {
      this.$router.push('/newProduct');
    },
    goback() {
      this.$router.go(-1);
    },
    imagePreview(e) {
      if (e.target.nodeName == "IMG") {
        if(e.target.dataset.fileid) {
          this.videoPopupShow = true;
          this.getVideo(e.target.dataset.fileid);
        } else {
          ImagePreview({
            images: [e.target.currentSrc],
            maxZoom: 10,
            showIndex: false,
            loop: false,
          });
        }
      } else {
        return;
      }
    },
    getVideo(fileid) {
      this.$axios.get('/Api/api/Web/Article/GetUI?vfid=' + fileid).then(res => {
        this.videoInfo = res.Data;
        this.initPopupVideo();
      })
    },
    initPopupVideo() {
      let Options = {};
      if(this.videoInfo) {
        Options = {
          fileID: this.videoInfo.fileid,
          appID: this.videoInfo.appid,
          psign: this.videoInfo.psign,
          licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1307224350_1/v_cube.license'
        };
        this.popupPlayer = this.TCPlayer('player-popup-id', Options, {
          app_Play_Id: this.videoInfo.app_Play_Id,
          app_Id: 'wcwechat',
          user_Id: this.videoInfo.user_Id,
          video_Id: 0
        },{
          Id: this.userInfo.Id,
          RealName: this.userInfo.RealName,
          HospitalTitle: this.userInfo.HospitalTitle,
          PositionalName: this.userInfo.PositionalName,
          SubjectName: this.userInfo.SubjectName,
          Phone: this.userInfo.Phone
        }, this.videoInfo.Name, this.videoInfo.videoSize, this.videoInfo.fileid).player;
      }
    },
    videoPopupClosed() {
      if(this.popupPlayer) {
        this.popupPlayer.dispose();
        const video = document.createElement('video');
        video.id = 'player-popup-id';
        video.style = 'width: 100%; height: 100%;';
        video.preload = 'auto';
        document.getElementById('videoPopupDiv').appendChild(video);
      }
    },
    explain() {
      this.$dialog.alert({
        title: '预约说明',
        messageAlign: 'left',
        message: '【直播预约】成功预约后，会在会前30分钟收到直播提示消息。\n【回看预约】成功预约后，会议回看视频上架后会收到提示消息。',
      }).then(() => {});
    },
    toReview() {
      if (this.RetArticleList.length > 1) {
        this.showRetArticleList = true;
      } else {
        this.toRetArticle(this.RetArticleList[0].Id, this.RetArticleList[0].Type);
      }
    },
    toRetArticle(id, type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          id: id,
          type: type
        }
      });
    },
    toQuestionnaire() {
      // window.location.href = 'https://www.wjx.cn/vm/etNd2Om.aspx#';
      this.$router.push('/SSIquestionnaire');
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100%;
  padding-bottom: 8em;
  box-sizing: border-box;
  position: relative;
  -webkit-text-size-adjust: none;
}

.title {
  width: 90%;
  margin: 0.5em auto;
  font-size: 20px;
  font-weight: bold;
  margin-top: 0;
  padding-top: 0.5em;
}

.item_label {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 90%;
  span {
    display: inline-block;
    height: 20px;
    line-height: 20px;
    border-radius: 5px;
    padding: 0.3em 1em;
    margin-right: 6px;
    margin-bottom: 5px;
    background-color: #f14d4d21;
    font-size: 12px;
    color: #CA001B;
  }
}

.timetab {
  position: relative;
  margin: 1em auto;
}

.time {
  width: 90%;
  margin: 0.5em auto;
  font-size: 12px;
  color: #adadad;
}

.fontSize {
  display: block;
  position: absolute;
  right: 2%;
  top: 50%;
  font-size: 0.9em;
  transform: translatey(-50%);
  color: #8b8b8b;
  vertical-align: middle;
  img {
    width: 4em;
    vertical-align: middle;
  }
}

.sicon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 1em;
  font-size: 12px;
  img {
    width: 1.5em;
    // vertical-align: baseline;
  }
}

.content1 {
  width: 90%;
  margin: auto;
  margin-top: 1.5em;
}

.content2 {
  width: 90%;
  margin: auto;
  margin-top: 1.5em;
}

.content3 {
  margin-top: 1em;
}

/deep/ .content1 img {
  display: block;
}

/deep/ .content2 img {
  display: block;
}

/deep/ .content3 img {
  display: block;
}

/deep/ .content1 video {
  width: 100%;
  height: 190px;
}

/deep/ .content2 video {
  width: 100%;
  height: 190px;
}

/deep/ .content3 video {
  width: 100%;
  height: 190px;
}

.detail {
  margin: auto;
  padding-bottom: 1em;
  border-bottom: 1px solid #ddd;
  img {
    margin: 0.8em 0;
  }
}

/deep/ .Desc > p > img {
  width: 100%;
}

/deep/ .VContent > p > img {
  width: 100%;
}

/deep/ .VContent img {
  width: 100%;
}

.detailzb {
  margin: auto;
  padding-bottom: 1em;
  border-bottom: 1px solid #ddd;
  img {
    margin: 0.8em 0;
  }
}

.article {
  width: 90%;
  margin: 1em auto;
}

.articletitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.3em;
}

.articleinfo {
  color: #6e6e6e;
  font-size: 14px;
}

/deep/ section {
  width: 100%;
  img {
    width: 100% !important;
    height: auto !important;
  }
  p {
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
}

.banner {
  /deep/ p {
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
}

/deep/ .articleinfo > p > img {
  width: 100%;
}

.dzmain {
  background: #f9f9f9;
  width: 83%;
  margin: auto;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 0.8em;
}

.dztitle {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
}

.ratename {
  display: inline-block;
  margin-right: 2em;
  vertical-align: middle;
  font-size: 15px;
  color: #606060;
}

.raterow {
  text-align: center;
  margin-bottom: 0.8em;
}

.van-rate {
  vertical-align: middle;
}

.rateupload {
  border: 0;
  width: 67%;
  text-align: center;
  display: block;
  margin: 1em auto !important;
  margin-top: 1.5em !important;
  border-radius: 0.5em;
  background: #d11919;
  font-size: 14px;
}

.rowbtn {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 1em;
  .headline {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0.5em;
  }
}

.plbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 34px;
  color: #CA001B;
  border: 1px solid #CA001B;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  img {
    width: 16px;
    height: 16px;
    margin-right: 3px;
  }
}

.plmain {
  width: 90%;
  margin: auto;
  margin-bottom: 2em;
}

.plrow {
  position: relative;
  margin-bottom: 1em;
}

.tx {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  img {
    width: 100%;
    display: block;
  }
}

.plname {
  display: inline-block;
  font-size: 14px;
  margin-left: 0.5em;
  color: #999999;
}

.pltop {
  position: relative;
}

.pltime {
  position: absolute;
  right: 0;
  font-size: 12px;
  top: 22%;
  color: #999999;
}

.pltext {
  font-size: 14px;
  color: #333333;
  padding-left: 34px;
}

.pldz {
  margin-left: 34px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 12px;
  img {
    width: 14px;
    height: 14px;
  }
  span {
    margin-left: 4px;
    color: #333333;
    font-size: 12px;
  }
}

.aboutmain {
  width: 90%;
  margin: auto;
}

.abouttop {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.aboutrow {
  background: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1em;
  padding: 0.5em 0;
}

.aboutimg {
  width: 45%;
  display: inline-block;
  vertical-align: middle;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  .imgmain {
    width: 100%;
    display: block;
  }
}

.aboutmaininfo {
  display: inline-block;
  width: 49%;
  margin-left: 2%;
  vertical-align: middle;
}

.abouttitle {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-weight: bold;
  min-height: 2.7em;
  font-size: 14px;
}

.aboutname {
  font-size: 12px;
}

.abouthos {
  font-size: 12px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.ssicon {
  font-size: 11px !important;
  vertical-align: middle;
  img {
    vertical-align: baseline !important;
  }
}

.playicon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2em;
  height: 2em;
  transform: translate(-50%, -50%);
}

.bottombar {
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  text-align: center;
  padding-bottom: 1.5em;
  padding-top: 0.5em;
}

.toTop {
  position: fixed;
  right: 13px;
  bottom: 60px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-bottom: 1.5em;
  padding-top: 0.5em;
  z-index: 99999;
  a {
    scroll-behavior: smooth;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.xcpsy {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 135px;
  right: 8px;
  img {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
  }
  span {
    font-size: 10px;
    color: #CA001B;
  }
}

.wytbl {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 272px;
  right: 18px;
  img {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
  }
  span {
    font-size: 10px;
  }
}

.pluswj {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 205px;
  right: 18px;
  .iconBox {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 3px;
  }
  img {
    width: 40px;
    height: 40px;
  }
  span {
    font-size: 10px;
    color: #CA001B;
  }
}

.bottomrow {
  display: inline-block;
  width: 25%;
}

.bottomimg {
  width: 1.5em;
}

.bottomtext {
  font-weight: bold;
  font-size: 0.9em;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  // padding-bottom: 1.5em;
  // padding-top: 1em;
  text-align: center;
  // background-color: #f9f9f9;
  border-radius: 10px;
  img {
    // width: 90% !important;
    width: 100%;
  }
}

.custom-indicator {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.3rem;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
  padding: 0.5em 1.5em;
  border-radius: 5em;
}

.subscribeBox {
  display: flex;
  position: relative;
}

.markIcon {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  top: -25px;
  color: #d11919;
  font-size: 14px;
  img {
    width: 16px;
    margin-left: 2px;
  }
}

.want {
  border: 0;
  width: 100%;
  text-align: center;
  display: block;
  margin: 1em auto !important;
  margin-bottom: 1em !important;
  border-radius: 0.5em;
  background: #d11919;
  font-size: 1.1em;
}

.ReviewWant {
  margin-left: 10px !important;
}

.disable {
  background: #C3C3C3;
}

.detailzb {
  width: 90%;
  // background: #f9f9f9;
  border-radius: 10px;
  border: 0;
  img {
    border-radius: 10px;
  }
}

.bannertop {
  position: absolute;
  top: 3%;
  left: 2.5%;
  font-weight: bold;
}

.filedownload {
  width: 77%;
  margin: 1em auto;
  background: #fbf4f4;
  border: 1px solid #ffadad;
  border-radius: 5px;
  position: relative;
  padding: 0.5em 1.5em;
  padding-top: 0.8em;
  padding-bottom: 2em;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 2em;
  }
}

.fileTitle {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 14px;
}

.filebottom {
  position: absolute;
  right: 1em;
  font-size: 11px;
  color: #CA001B;
  bottom: 0.5em;
}

.plPopup {
  padding-top: 3em;
}

.plinput {
  margin: auto;
  margin-bottom: 2em;
  width: 90%;
  border: 1px solid #CA001B;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 1em;
}

.frame {
  width: 100%;
  height: 13em;
}

.shlog {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  .shin {
    position: fixed;
    width: 100%;
    top: 20px;
    left: 0;
    .shlog1 {
      display: block;
      width: 16%;
      margin: 0 auto;
      margin-top: 0.3rem;
      margin-right: 9%;
    }
    .shlog2 {
      width: 61%;
      display: block;
      margin: 0 auto;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .shlog3 {
      color: #fff;
      font-size: 16px;
      width: 70%;
      margin: 0 auto;
    }
    .sslog {
      width: 22px;
      margin-right: 5px;
      vertical-align: middle;
    }
    .sslog1 {
      vertical-align: middle;
      width: 22px;
      margin: 0 5px;
    }
    span {
      vertical-align: middle;
    }
    .shlog4 {
      color: #fff;
      font-size: 16px;
      margin: 0 auto;
      margin-bottom: 0.5rem;
      width: 70%;
    }
    .known {
      width: 56%;
      text-align: center;
      margin: 0 auto;
      background: rgba(255, 255, 255, 0.1);
      border: 2px dashed #fff;
      border-radius: 5px;
      padding: 10px;
      margin-top: 1rem;
    }
  }
}

.QRCodeDialog {
  position: fixed;
  top: 50%;
  left: 15%;
  width: 70%;
  height: 300px;
  padding: 15px;
  transform: translateY(-150px);
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  border: 1px solid #ebedf0;
  box-shadow: 0 4px 6px #ebedf0;
  .QRCodeImg {
    width: 80%;
  }
  .closeQRCodeDialog {
    display: inline-block;
    font-size: 12px;
    padding: 8px 40px;
    background-color: #F96619;
    color: #FFFFFF;
    border-radius: 3px;
  }
}

.noComment {
  text-align: center;
  img {
    width: 180px;
    margin-bottom: 20px;
  }
  span {
    display: block;
    font-size: 12px;
    color: #CCCCCC;
  }
}

.RetArticleListBox {
  position: relative;
  width: 300px;
  padding: 20px 10px;
  box-sizing: border-box;
  border-radius: 8px;
  .icon_close {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .retTitle {
    position: absolute;
    left: 10px;
    top: 6px;
    font-size: 15px;
    font-weight: bold;
  }
  .listBox {
    margin-top: 20px;
  }
  .retList {
    padding: 10px;
    border: 1px solid #DDDDDD;
    border-radius: 6px;
  }
  .retList:not(:last-of-type) {
    margin-bottom: 10px;
  }
}
.competitionBox {
  width: 90%;
  margin: 20px auto;
  .tips1 {
    font-size: 16px;
    color: #333333;
    text-align: center;
    margin-bottom: 10px;
  }
  .tips2 {
    font-size: 12px;
    color: #CCCCCC;
  }
  .lymQRcode {
    display: block;
    width: 50%;
    margin: 0 auto;
  }
}
.videoPopupBox .van-popup--center {
  width: 100%;
}
.videoPopupBox .van-overlay {
  // background-color: rgba(0,0,0, 1) !important;
  background-color: black;
}
</style>